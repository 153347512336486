import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🥑-nutrient"
    }}>{`🥑 Nutrient`}</h1>
    <p>{`Welcome to Nutrient. A Component Design Framework`}</p>
    <p>{`Nutrient serves as Foodsby's projects and is a collection of lower-level components, base styles, theming variables, and utilities but also lends our UX patterns and practices to the UI components.`}</p>
    <p>{`Any and all updates are welcome! When working with Nutrient any bugs should be reported to QA and your respective product teams. `}</p>
    <p>{`Overriding styles outside of Nutrient should only occur for one-offs, prototypes, and testing variants.`}</p>
    <h2 {...{
      "id": "recomended-plugins"
    }}>{`Recomended Plugins`}</h2>
    <p>{`To help you work more effectively in Nutrient here is a shortlist of plugins. (there will be more than one in the future)`}</p>
    <ul>
      <li parentName="ul">{`Color Highlight`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      